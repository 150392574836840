import React from "react"

const policyPage = () => {
  return (
    <div style={{ marginTop: "100px" }} className="policy-page">
      <h1 dir="RTL">
        <a name="_MailOriginal">תקנון מדיניות ופרטיות</a>
      </h1>
      <p dir="RTL">עודכן לאחרונה באוקטובר 2022</p>
      <ol start="1" type="1">
        <li dir="RTL">
          ברוכים הבאים לאתר אינטרנט זה של חברת <strong>וואן בי אי</strong>
          <strong> </strong>" בע"מ, ח.פ. 514207430והחברות הקשורות לה (להלן: " <strong>וואן בי אי</strong>
          <strong> </strong>" או "<strong>אנו</strong>
          "). כל האמור בלשון זכר, משמעו גם בלשון נקבה וכל האמור בלשון יחיד, משמעו אף בלשון רבים.
        </li>
        <li dir="RTL">
          השימוש באתר זה של וואן בי אי כפוף לתקנון ומדיניות פרטיות זה, כפי שיעודכן מעת לעת (להלן: "
          <strong>התקנון</strong>"). לפני השימוש באתר, ובכלל זה מסירת מידע מכל סוג שהוא, הנך מתבקש לקרוא
          בעיון את התקנון המהווה את הבסיס המשפטי לכל דיון בינך לבין וואן בי אי בכל הקשור לאתר ולשימוש בו.
        </li>
        <li dir="RTL">
          בעצם השימוש שלך באתר הנך מאשר שקראת והבנת את התקנון ואתה מסכים לכל תנאיו. אם אינך מקבל את תנאי
          התקנון, כולם או חלקם, הנך מתבקש שלא לעשות שימוש באתר בכל צורה שהיא.
        </li>
      </ol>
      <ul type="circle">
        <li dir="RTL">
          <strong>בתקנון זה יהיו למונחים הבאים הפירוש שבצידם</strong>
          <strong>:</strong>
        </li>
        <ul type="square">
          <li dir="RTL">
            <strong>"</strong>
            <strong>אתה</strong>
            <strong>" </strong>
            <strong>או </strong>
            <strong>"</strong>
            <strong>משתמש</strong>
            <strong>"</strong>
            <strong>או </strong>
            <strong>"</strong>
            <strong>הנך</strong>
            <strong>"- </strong>
            <strong>
              משמעו כל גולש אשר עושה שימוש באתר באמצעות מחשב ו/או כל מכשיר קצה אחר, לרבות באמצעות מכשיר
              סלולארי או תקשורת סלולארית אחרת ו/או כל מכשיר נייח/נייד אחר
            </strong>
            <strong>.</strong>
          </li>
          <li dir="RTL">
            <strong>"</strong>
            <strong>תוכן</strong>
            <strong>" –</strong>
            <strong>
              משמעו מידע מכל סוג שהוא המצוי באתר, לרבות (אך לא רק) מידע טקסטים, תוכן מילולי, חזותי, מפות,
              עיצוב, תצוגת האתר, שפה גרפית, קטעי וידאו, חומרים, נתונים, תמונות, צילומים, איורים, קבצי
              קול, קבצים גרפיים, סימני מסחר, קבצים מסוגים אחרים, קישורים, וכל מידע אחר המופיע או מוצג
              באתר, לרבות אם הועלה לאתר על ידי צד ג
            </strong>
            <strong>'.</strong>
          </li>
          <li dir="RTL">
            <strong>"</strong>
            <strong>שימוש באתר</strong>
            <strong>" – </strong>
            <strong>
              משמעו כל גלישה ו/או כניסה ו/או צפייה ו/או ביקור של משתמש באתר, כהגדרתו לעיל, לכל צורך שהוא,
              לרבות לצורך השימוש בתכניו ובשירותים המוצעים בו. האתר ותכניו מיועדים לשימוש אישי בלבד ואין
              לעשות בהם כל שימוש מסחרי ללא הרשאה מפורשת מוואן בי אי
            </strong>
            <strong> .</strong>
          </li>
          <li dir="RTL">
            <strong>"</strong>
            <strong>שירות</strong>
            <strong>" </strong>
            <strong>או </strong>
            <strong>"</strong>
            <strong>שירותים</strong>
            <strong>" – </strong>
            <strong>משמעם השירותים אותם מציעה ו/או תציע וואן בי אי באתר מעת לעת</strong>
            <strong>.</strong>
          </li>
          <li dir="RTL">
            <strong>"</strong>
            <strong>מידע אישי</strong>
            <strong>" – </strong>
            <strong>
              מידע המזהה או עשוי לזהות אותך באופן אישי (כגון שם ושם משפחה, דוא"ל, כתובת, מקום מגורים
            </strong>
            <strong>,</strong>
            <strong>וכו</strong>
            <strong>).</strong>
          </li>
          <li dir="RTL">
            <strong>"</strong>
            <strong>מידע שאינו אישי</strong>
            <strong>" – </strong>
            <strong>
              מידע אנונימי, סטטיסטי ומצטבר שנאסף ע"י וואן בי אי באופן פאסיבי אודות אופן שימושך באתר ו/או
              בשירותים המוצעים בו (למשל – סוג הדפדפן שלך, התאריך והשעה בה נכנס משתמש לאתר, המקומות בהם
              ביקרת באתר וכו'). מידע זה אינו מזהה אותך באופן אישי
            </strong>
            <strong>.</strong>
          </li>
        </ul>
      </ul>
      <ol start="4" type="1">
        <li dir="RTL">
          <strong>
            <u>האתר והשימוש בו</u>
          </strong>
        </li>
      </ol>
      <ul type="circle">
        <li dir="RTL">
          האתר הינו אתר בינה מלכותית שנועד לאפשר למשתמשים ליצור טקסטים מגוונים כגון :שירים ,סיפורים
          ,ברכות ,מאמרים ,סקריפט לפיתוח ,תמונות ,עיצובים מידע כללי וכיוב', וכולל אפשרות ליצירת קשר לקבלת
          מידע ופרטים על וואן בי אי ו/או על השירותים המוצעים על ידה,
        </li>
        <li dir="RTL">
          לצורך קבלת מידע או פרטים נוספים אודות וואן בי אי ו/או השירותים המוצעים על ידה ללקוחותיה, מעבר
          למידע המצוי באתר, הנך יכול לשלוח פניה בלשונית "צור קשר" שבאתר, בה אתה עשוי להידרש למסור מספר
          פרטים מזהים אודותיך (כדוגמת שם, כתובת דוא"ל, טלפון, חברה) וכן הנך יכול לשלב מלל חופשי ("{" "}
          <strong>פרטי קשר</strong>").
        </li>
        <li dir="RTL">
          הנך מוזמן לצפות בתוכן שבאתר, לעשות בו שימוש, להוריד תוכן לשימוש אישי ובלתי מסחרי בלבד, להעביר
          פניה באמצעות לשונית "צור קשר", והכל למטרות חוקיות בלבד, בכפוף להוראות תקנון זה ולהוראות כל דין
          במדינת ישראל, לרבות בכל הקשור בזכויות יוצרים וקניין רוחני.
        </li>
        <li dir="RTL">
          הנתונים הנמסרים על ידך באתר נמסרים מיוזמתך. חלק מהמידע הנמסר על ידך הוא מידע אישי, לדוגמה,
          פרטים שנמסרו על ידך במועד הגשת פנייה יזומה אלינו מטעמך (כגון במסגרת "צור קשר") וחלק מהמידע הינו
          מידע שאינו אישי כגון מידע שנאסף אודות המשתמש ופעולותיו במהלך גלישה וביצוע פעולות באתר.
        </li>
        <li dir="RTL">
          אנו משתמשים במידע האישי ובמידע שאינו אישי כדי לספק לך מידע שאתה מבקש ולמטרות המתוארות בעת איסוף
          הנתונים כמפורט בתקנון זה, אולם בכל האמור במידע אישי, וואן בי אי תעשה בו שימוש בדרך העולה בקנה
          אחד עם המטרות לשמן הוא הוכנס.
        </li>
        <li dir="RTL">
          אנו שומרים מידע אישי הנמסר על ידך כדי לספק לך שירותים ולעיתים למשך זמן נוסף, ככל הנדרש על פי
          דין.
        </li>
        <li dir="RTL">
          הינך מסכים לכך שנשתמש במידע האישי אשר מסרת מרצונך על מנת לשלוח אליך הצעות ומסרים פרסומיים ביחס
          לשירותי וואן בי אי , לרבות לפי חוק התקשורת (בזק ושידורים), תשמ"ב-1982, באמצעות דואר אלקטרוני
          ו/או מסרונים (SMS).באפשרותך לבטל את הסכמתך לכך באמצעות משלוח הודעה לכתובת{" "}
          <a href="mailto:Marketing@one1.co.il">Marketing@one1.co.il</a>.
        </li>
        <li dir="RTL">
          מובהר, כי כל מידע או חומרים שתעביר דרך האתר למעט מידע אישי, לא ייחשב כמידע סודי או כקנייני
          ולוואן בי אי לא תהא כל אחריות לגביו. כמו כן, וואן בי אי תהא רשאית לעשות בו או בכל חלק ממנו כל
          שימוש שתמצא לנכון כולל העתקתו, הפצתו, גילויו, עיבודו וכיוב', וזאת לכל מטרה שתראה לה, בין מסחרית
          ובין שאינה מסחרית, לרבות דיוור ישיר ו/או משלוח חומרים פרסומיים ובעצם מסירת המידע על ידך באתר
          (מידע אישי ומידע שאינו אישי) אתה מעניק לוואן את הזכות לעשות שימוש בו כאמור בתקנון זה, ללא כל
          תמורה.
        </li>
      </ul>
      <li dir="RTL">
        <strong>
          <u>מגבלות השימוש באתר</u>
        </strong>
      </li>
      <ul type="circle">
        <li dir="RTL">
          על כל משתמש העושה שימוש באתר חל איסור מוחלט לעשות באתר כל שימוש בניגוד לתקנון זה ו/או שימוש
          בלתי חוקי ו/או בלתי מוסרי ו/או בלתי סביר ביחס למטרה שלשמה נועד האתר, ובכלל זה:
        </li>
        <ul type="square">
          <li dir="RTL">
            אין לבצע כל פעולה המטילה עומס גדול באופן בלתי סביר או בלתי פרופורציונאלי על האתר ו/או על
            התשתית הקשורה אליו.
          </li>
          <li dir="RTL">
            אין לעשות כל פעולה באתר או בחלק ממנו באופן שיגרום ו/או עשוי לגרום לוואן בי אי ו/או מי מטעמה
            ו/או מי מהמשתמשים באתר נזק מכל סוג שהוא. בכלל זאת, אין לעקוף את האמצעים בהם נוקטת וואן בי אי
            להגנה על פרטיות המשתמשים.
          </li>
          <li dir="RTL">אין לזייף ו/או להתחזות לאדם ו/או לכל גורם אחר, שאינו המשתמש.</li>
          <li dir="RTL">
            אין לחדור לקבצי המידע של וואן בי אי ו/או באתר ו/או לתוכן ו/או לקבצי מחשב של וואן בי אי ו/או
            באתר ו/או להעביר תוכנת מחשב, קוד מחשב או יישום הכוללים וירוסים, סוס טרויאני, תולעים, וכיוצ"ב
            תוכנות עוינות ו/או יישומים מזיקים ו/או לעשות כל פעולה בניגוד לחוק המחשבים, התשנ"ה – 1995.
          </li>
          <li dir="RTL">
            אין ליצור מאגר מידע על ידי הורדה ואחסון שיטתיים של כל או חלק מהתוכן באתר, בין היתר באמצעות
            כלים אוטומטיים וטכנולוגיות לסריקת מידע, כריית מידע ו/או אחזור אוטומטי או איסוף של מידע מתוך
            האתר (automated data mining tools", "crawlers") וכו'.
          </li>
          <li dir="RTL">
            אין לשנות את האתר ו/או תכניו, להעתיקם, לשכפלם, להפיצם, למוכרם, להרשות שימוש, להציגם בפומבי,
            לבצעם ,לפרסמם, ליצור נגזרות מהם, ליצור מוצר או תוצר או חומר כלשהו תוך שימוש בתכנים או להשתמש
            בתכנים בדרך אחרת למטרה ציבורית או מסחרית כלשהי.
          </li>
          <li dir="RTL">
            למעט וככל שנאמר אחרת במפורש בתקנון, כל התכנים, העיצוב והתצוגה ("התכנים"), מהווים קניין רוחני
            של וואן בי אי או צדדי ג', ומוגנים על-פי כל דין. שימוש בלתי מורשה בתכנים או בכל חלק מהם עלול
            להוות עבירה על החוק.
          </li>
          <li dir="RTL">
            סימני מסחר ושמות חברות או מוצרים הנזכרים באתר שייכים לבעליהם. אין בשימוש באתר בכדי להקנות לך
            זכויות בקניין רוחני של וואן בי אי או של צדדים שלישיים המפורסמים באתר.
          </li>
          <li dir="RTL">
            וואן בי אי רשאית על פי שיקול דעתה וללא התראה מראש, למנוע ממך ו/או לחסום ו/או להגביל גישתך
            לאתר או לחלק ממנו, אם הפרת את תנאי התקנון כולם או חלקם) ו/או אם ביצעת מעשה או מחדל הפוגעים או
            העלולים לפגוע בחברה או בצדדים שלישיים כלשהם.
          </li>
          <li dir="RTL">
            אם תפר אחד או יותר מן התנאים האלה תפוג אוטומטית הרשאה הניתנת לך להשתמש בתכנים המצויים באתר,
            ותחול עליך החובה להשמיד מיד כל תוכן שהורד או הודפס על ידך מהאתר למטרה כלשהיא.
          </li>
        </ul>
      </ul>
      <ol start="7" type="1">
        <li dir="RTL">
          <strong>
            <u>פרטיות</u>
          </strong>
          <strong>
            <u>:</u>
          </strong>
        </li>
      </ol>
      <p dir="RTL">
        <u>מידע אישי</u>
      </p>
      <ul type="disc">
        <li dir="RTL">
          וואן בי אי מכבדת את פרטיותך, ומחויבת להגן עליה. כל האמור תקנון זה הינו בכפוף לחוק הגנת הפרטיות,
          התשמ"א-1981, תקנותיו והוראות רשם מאגרי המידע ("<strong>חוק הגנת הפרטיות</strong>") ולהוראות כל
          דין.
        </li>
        <li dir="RTL">
          ידוע לך שלא מוטלת עליך חובה חוקית למסור לחברה את מידע המשתמש ו/או כל מידע או נתון אחר באתר,
          ומסירת מידע משתמש ו/או כל מידע או נתון אחר נעשית בהתאם לרצונך החופשי, כמפורט לעיל.
        </li>
        <li dir="RTL">
          ככל שהינך פונה בשם או עבור חברה/ארגון/גוף ומוסר באתר פרטי קשר וואן בי אי תהא רשאית לעשות שימוש
          בפרטיך לצורך יצירת הקשר איתך בהתאם לנושא פנייתך, מתן ואספקת שירותיה, הצעת שירותים נוספים,
          התנהלות שוטפת, משלוח מידע ביחס לכל אלה וכן לצורך משלוח חומרים פרסומיים ו/או שיווקיים.
        </li>
        <li dir="RTL">
          בכפוף להוראות חוק הגנת הפרטיות הינך זכאי לעיין בפרטיך הרשומים במאגרי המידע של וואן בי אי ,
          ולבקש לתקן, להימחק מרשימת התפוצה של נמעני הדיוור הישיר (ובמסגרת זו ימחקו גם פרטי המידע שיצרו את
          האפיון עליו התבססה פניה בדיוור ישיר, ככל שנשלחו אליך) ו/או לבטל הסכמתך כי יעשה בפרטיך המצויים
          במאגר המידע שימוש לדיוור ישיר ו/או שיועברו לצדדים שלישיים על בסיס זמני או קבוע וכן לסרב לעשיית
          שימוש בפרטים שמסרת בלשונית "צור קשר". ידוע לך כי הדבר עשוי לפגוע ביכולת של וואן בי אי להיענות
          לפנייתך ו/או לספק לך את המבוקש בפנייתך.
        </li>
        <li dir="RTL">
          אם ברצונך לעשות כן, עליך לשלוח הודעה על כך בדואר רשום או בדואר אלקטרוני לכתובת marketing
          @one1.co.il , והחברה תפעל בהתאם להוראות חוק הגנת הפרטיות.
        </li>
      </ul>
      <p dir="RTL">
        <u>מידע לא אישי</u>
      </p>
      <ul type="disc">
        <li dir="RTL">
          בעצם השימוש שלך באתר, הינך מסכים לאיסוף מידע לא אישי ע"י וואן בי אי אשר ישמש אותה, בין היתר,
          לצורך עריכת ניתוחים ועיבודים סטטיסטיים, בחינת מספר המבקרים באתר, ניתוח אופי השימוש באתר, תיקון
          תקלות ותמיכה טכנית, ניהול האתר, כל מטרה אחרת שנועדה לניהול ולשיפור האתר ו/או השירותים המוצעים
          בו, וכל שימוש חוקי אחר כפי שתמצא לנכון (כולל העברתו לצד ג') ללא כל הגבלה וללא קבלת הסכמתך,
          ולוואן בי אי לא תהא כל אחריות בעניין. הינך רשאי לנטרל או לחסום כלים אלה אולם הדבר עשוי לפגוע
          ביכולתך לעשות שימוש באתר.
        </li>
      </ul>
      <ol start="8" type="1">
        <li dir="RTL">
          <strong>
            <u>"</u>
          </strong>
          <strong>
            <u>עוגיות</u>
          </strong>
          <strong>
            <u>" (Cookies)</u>
          </strong>
        </li>
      </ol>
      <ul type="circle">
        <li dir="RTL">
          האתר עושה שימוש ב"עוגיות' (קבצי טקסט שהדפדפן של המשתמש יוצר לפי פקודה ממחשבי וואן בי אי ) וזאת
          למטרות שונות, כגון: איסוף נתונים סטטיסטיים, תפעול שוטף, ואבטחת מידע, לצורך הנ"ל האתר עשוי
          להסתייע בשירותי צד שלישי כגון Google Analytics.
        </li>
        <li dir="RTL">
          לידיעתך, מחיקת עוגיות וחסימתן תשפיע על שימושך באתר, וחלקים ממנו עלולים שלא לעבוד.
        </li>
      </ul>
      <li dir="RTL">
        <strong>
          <u>מגבלת אחריות</u>
        </strong>
      </li>
      <ul type="circle">
        <li dir="RTL">
          וואן בי אי אינה מחייבת אותך לעשות שימוש באתר. כל שימוש באתר נעשה מרצונך החופשי. בהתאם, המשתמש
          בלבד אחראי בלעדית לאופן השימוש שהוא עושה או יעשה באתר ולכל הקשור והנובע מכך, ולוואן בי אי לא
          תהיה כל אחריות כלפיך ו/או כלפי צד ג' בקשר עם האתר ו/או השימוש בו.
        </li>
        <li dir="RTL">
          וואן בי אי לא תהיה אחראית בגין דיוק ו/או שלמות ו/או התאמה לתכלית של כל הודעה שתישלח אליך.
        </li>
        <li dir="RTL">
          וואן בי אי אינה אחראית בכל אופן שהוא לשיבוש מידע המשתמש (כולו או חלקו) שנמסר באתר ו/או אי קבלת
          הפניה, אי היענות לפניה, לכל עיכוב במענה לפניה ו/או אי קבלת מידע מספיק באתר.
        </li>
        <li dir="RTL">
          התכנים באתר מוצגים כפי שהם (AS IS). וואן בי אי אינה נותנת כל מצג ו/או התחייבות בקשר לאתר ו/או
          לתכניו ו/או למהימנותם ו/או דיוקם ו/או התאמתם לצרכי המשתמש או לצרכי צד ג'.
        </li>
        <li dir="RTL">
          אין להסתמך על כל תוכן באתר לצורך קבלת החלטות כלשהן, לרבות בנושאים משפטיים, עסקיים, מקצועיים,
          מסחריים,רפואיים, אישיים, כספיים ואחרים, וכל הסתמכות ו/או החלטה שלך לפעול על בסיס התכנים
          המופיעים באתר מבלי לוודא את נכונותם ו/או חוקיותם ו/או התאמתם לצרכיך הינה באחריותך המלאה
          והבלעדית, ואתה פוטר את וואן בי אי מאחריות לכל פגיעה, נזק, תביעה, דרישה, חבות חיסרון כיס, הפסד
          או כל תשלום אחר שיגרמו לך בקשר עם שימושך ו/או הסתמכותך על התכנים.
        </li>
        <li dir="RTL">
          וואן בי אי אינה מתחייבת כי האתר או איזה מחלקיו יפעל בצורה רציפה, מלאה, וכי הגישה אליו תהיה
          זמינה או נקייה מתקלות ולא תופרע. המשתמש מודע לכך שייתכנו שיבושים ו/או הפסקות ו/או תקלות ו/או
          עיכובים בפעולת האתר, והוא פוטר את וואן בי אי מכל אחריות לנזקים ו/או הוצאות ו/או תשלומים שייגרמו
          לו בשל כך. במידה ויהיו ליקויים באתר, הנך מוזמן לדווח על כך באמצעות משלוח הודעת דוא"ל לכתובת{" "}
          <a href="mailto:Marketing@one1.co.il">Marketing@one1.co.il</a>
        </li>
        <li dir="RTL">
          על אף האמור בכל דין או הסכם, בשום מקרה לא תחול על וואן בי אי ו/או בעלי מניותיה ו/או עובדיה ו/או
          מנהליה ו/או הדירקטורים בה ו/או על כל מי מטעמם של כל אלה, אחריות בגין נזקים כלשהם (לרבות, אך לא
          רק, נזקים ישירים, עקיפים, עונשיים, מיוחדים או תוצאתיים, לרבות נזקים הנובעים מאובדן רווחים או
          אובדן הכנסות, אובדן נתונים, אובדן מסמכים, שחזור קבצים, הפרעה למהלך העסקים, פגיעה במוניטין
          וכיוצ"ב) בקשר עם ו/או כתוצאה מהכניסה לאתר, השימוש בו, העברת מידע אישי באמצעותו, מאי יכולת
          להיכנס ו/או להשתמש באתר או מתוצאות השימוש באתר, בכל אתר אחר המקושר לאתר, או בתכנים הכלולים באתר
          (לרבות תכנים בקשר לשירותי צדדים שלישיים) ו/או בכל אחד מאתרים המקושרים לאתר ו/או כתוצאה מחשיפה
          לפגעי מחשב (לרבות וירוסים) במהלך השימוש באתר ו/או כתוצאה מהסתמכות על התכנים הכלולים באתר ו/או
          כל חלק ממנו ו/או עקב פגיעה בזכויות קניין, זכויות יוצרים, סימנים ושמות מסחריים, סודות מסחריים,
          פטנטים ומדגמים, בין שהם מוגנים ובין שאינם מוגנים, והכל – גם אם וואן בי אי כבר קיבלה מידע אודות
          האפשרות להתרחשות נזקים אלה, תהא אשר תהא עילת התביעה או סיבת הנזק, בין חוזית, בין נזיקית ובין
          אחרת. הסעד היחיד המוקנה לך בכל מקרה ועל אף האמור בכל דין או הסכם, הנו הפסקת השימוש באתר.
        </li>
      </ul>
      <li dir="RTL">
        <strong>
          <u>שינויים ו/או עדכונים בתוכן</u>
        </strong>
      </li>
      <ul type="circle">
        <li dir="RTL">
          וואן בי אי שומרת על זכותה לעשות כל שינוי או עדכון מכל סוג באתר ו/או בתוכנו כולל הפסקת השירותים
          בו – כולם או חלקם, ללא הודעה מראש. במקרה של הפסקת השירות, קבצת וואן לא תהא אחראית כלפיך או כלפי
          צד שלישי כלשהו בגין נזק כלשהו ו/או אובדן.
        </li>
      </ul>
      <li dir="RTL">
        <strong>
          <u>קניין רוחני</u>
        </strong>
      </li>
      <ul type="circle">
        <li dir="RTL">
          מלוא זכויות הקניין הרוחני מכל סוג שהוא באתר, על כל חומריו והתכנים שבו (לרבות העיצוב ותצוגת
          האתר), שייכים לוואן בי אי (ו/או לצדדי ג' לפי העניין) ווואן בי אי (או צדדי ג' לפי העניין) הינה
          הבעלים הבלעדי בשמות ובסימנים המסחריים של האתר, בפטנטים ובמדגמים של האתר, בין שהם רשומים ובין
          שאינם רשומים, בזכויות יוצרים, בסודות מסחריים הכרוכים בהפעלת האתר, בעיצוב האתר, ובמידע הטכנולוגי
          הכרוך בהפעלתו.
        </li>
        <li dir="RTL">
          וואן בי אי מקנה לך את הזכות לעשות שימוש באתר, בתנאי שתפעל בהתאם לתנאי השימוש ולכל ההודעות בדבר
          זכויות קניין רוחני הכלולות בתכנים. מבלי לגרוע מהאמור:
        </li>
        <ul type="square">
          <li dir="RTL">
            התכנים מוגנים ו/או עשויים להיות מוגנים תחת זכויות יוצרים, סימני מסחר, פטנטים או זכויות קניין
            רוחני אחרות. בהתאם, כל שימוש שלך בהם יעשה אך ורק על פי הרשאה מפורשת מראש ובכתב של וואן בי אי
            . כל שימוש בלתי מורשה בכל תוכן שבאתר עלול להוות עבירה על חוקי זכויות יוצרים, חוקי סימני מסחר
            וחוקים אחרים, ויגרום לכך שהרשאת השימוש שלך באתר תפוג באופן אוטומטי. במצב זה, תחול עליך החובה
            להשמיד מיד כל תוכן שהורד או הודפס מהאתר.
          </li>
          <li dir="RTL">
            אינך רשאי לשנות את האתר ו/או התכנים שבו בצורה כלשהי, ובכלל זה אינך רשאי להעתיק, לשכפל, להפיץ,
            להעביר, למכור, להרשות שימוש, להציג בפומבי, לבצע, לפרסם, לנסות לפענח או לבצע הנדסה לאחור,
            ליצור נגזרות, ליצור מוצר או תוצר או תוכן כלשהו תוך שימוש בתכנים או להשתמש בתכנים בדרך אחרת
            לכל מטרה שאינה לשימוש אישי ופרטי (בכפוף לתנאי השימוש), לרבות למטרה ציבורית או מסחרית כלשהי.
          </li>
          <li dir="RTL">
            למעט הענקת זכות השימוש המוגבלת באתר ובתכנים בו כמפורט בתנאי שימוש אלה, וואן בי אי אינה מעניקה
            לך כל רישיון שימוש בקניין הרוחני של וואן בי אי ו/או של צדדי ג' שהתכנים שייכים או קשורים להם.
          </li>
          <li dir="RTL">
            אינך רשאי ליצור מסגרות (Frame) לאתר ו/או על שימוש ב-Meta Tags או כל טקסט חבוי אחר תוך שימוש
            בשמה של וואן בי אי או בסימני המסחר שלה ללא אישורה מראש ובכתב ובכפוף לתנאי האישור, אם וככל
            שיינתן לפי שיקול דעתה הבלעדי. כל שימוש בלתי מורשה יגרור, בין היתר, הפסקת זכותך להשתמש באתר.
          </li>
        </ul>
      </ul>
      <ol start="12" type="1">
        <li dir="RTL">
          <strong>
            <u>אבטחת מידע</u>
          </strong>
        </li>
      </ol>
      <ul type="circle">
        <li dir="RTL">
          וואן מטמיעה ומיישמת מערכות ונהלים לאבטחת מידע במערכותיה, לרבות באלו המאחסנות את המידע הנמסר על
          ידך והמתקבל והנאסף באמצעות האתר. כל אלה מצמצמים את הסיכונים לחדירה בלתי מורשית למערכות וואן בי
          אי והונאות מקוונות אחרות, אך אין בהם בכדי להעניק ביטחון מוחלט ו-וואן בי אי אינה מתחייבת כי
          השירותים באתר יהיו חסינים בצורה מוחלטת מפני גישה בלתי מורשת למידע בהם לרבות למידע האישי שנמסר
          על ידך. סיכונים אלה עלולים להתממש.
        </li>
      </ul>
      <li dir="RTL">
        <strong>
          <u>כללי</u>
        </strong>
      </li>
      <ul type="circle">
        <li dir="RTL">
          האתר מיועד לשימוש עבור בגיר (מעל גיל 18) בתחום הריבונות של מדינת ישראל, ואינו מיועד לקטינים.
          באם הינך קטין (מתחת לגיל 18), עצם שימושך באתר יהווה ראיה לכך שקיבלת אישור מאפוטרופוס חוקי שמונה
          לך לעשות שימוש זה.
        </li>
        <li dir="RTL">
          וואן בי אי שומרת לעצמה את הזכות בכל עת לסגור את האתר ו/או להפסיק את פעילותו, לעדכן את מבנהו,
          תכניו וכן את תנאי התקנון, ללא הודעה מראש.
        </li>
        <li dir="RTL">
          האתר מכיל או עשוי להכיל קישורים לאתרי אינטרנט של צדדים שלישיים. כאשר תקליק על אחד מקישורים אלה
          תתחבר לאתר אינטרנט אחר שאינו בשליטת ו/או שייך לוואן בי אי , אשר עלול לאסוף מידע אודותיך. וואן
          בי אי אינה אחראית, אינה נושאת באחריות לאותם אתרים, לכל מידע (כולל מידע אישי) שנאסף בהם, אם נאסף
          ולא תחול עליה כל אחריות בקשר אליהם.
        </li>
        <li dir="RTL">
          לשם נוחות המשתמשים באתר ולשם כך בלבד, האתר עשוי להציע קישורים/הפניות לאתרי אינטרנט של צד ג'.
          במקרה שתשתמש בקישורים/הפניות אלה, תצא מהאתר. וואן בי אי אינה מתחייבת כי הפניות ו/או קישורים
          כאמור יובילו לאתרים פעילים, לא בדקה את כל אתרי צד ג' אלה, אין לה שליטה עליהם ואין היא אחראית
          לאתרים אלה או לתוכנם. ההחלטה לגשת לאתר צד ג' כלשהו המקושר לאתר היא באחריותך בלבד.
        </li>
        <li dir="RTL">
          אם ייקבע כי חלק כלשהו של התנאים אינו תקף או שאינו ניתן לאכיפה על פי דין, אזי הסעיפים שנשלל
          תוקפם או שאינם ניתנים לאכיפה ייחשבו כאילו הוחלפו בסעיפים תקפים וניתנים לאכיפה שתוכנם תואם במידת
          הקירוב הגדולה ביותר את כוונת הסעיפים המקוריים, ואילו יתר סעיפי התנאים יישארו בתוקפם.
        </li>
        <li dir="RTL">
          לא תהא רשאי להמחות ו/או להאציל ו/או להעביר בכל דרך אחרת את זכויותיך ו/או התחייבויותיך, כולן או
          כל אחת מהן לפי תנאי שימוש אלו.
        </li>
        <li dir="RTL">
          ויתרה וואן בי אי , מפורשות או מכללא, על זכות הניתנת לה על פי תנאי שימוש אלו במקרה מסוים לא
          ילמדו מכך גזירה שווה לכל מקרה אחר ולא יראו בכך ויתור על זכויותיה.
        </li>
        <li dir="RTL">
          אתה תשפה את וואן בי אי , עובדיה, מנהליה ו/או מי מטעמם, מיד עם דרישתם הראשונה, בגין כל נזק,
          הפסד, אובדן רווח, תשלום או הוצאה שייגרמו למי מהם, ובכלל זה שכ"ט עו"ד והוצאות משפט, עקב הפרת
          תנאי תקנון זה.
        </li>
        <li dir="RTL">
          תנאים אלה מהווים את ההסכם השלם והמלא בין המשתמש ווואן בי אי בקשר עם האתר, ומחליפים כל תקשורת או
          הצעה קודמת או אחרת, בין במתכונת אלקטרונית, ובין בעל-פה או בכתב, שעברו בין המשתמש ווואן בי אי
          ו/או כל מי מטעמה בקשר לאתר.
        </li>
        <li dir="RTL">
          על האתר, ועל תנאי השימוש יחולו חוקי מדינת ישראל והם יפורשו על פיהם. מקום השיפוט הבלעדי בכל
          הקשור בתנאים ו/או באתר הינו בבתי המשפט המוסמכים בפתח תקוה.
        </li>
      </ul>
      <li dir="RTL">
        <strong>
          <u>יצירת קשר / שאלות</u>
        </strong>
      </li>
      <ul type="circle">
        <li dir="RTL">
          במידה ויהיו לך שאלות נוספות בנוגע לתקנון שלנו או אם ברצונך לדווח על פגיעה בפרטיות, אתה מוזמן
          ליצור קשר <a href="mailto:%D7%91marketing@one1.co.il">בMarketing@one1.co.il</a>
          או בדואר בכתובתבנו המופיעה באתר.
        </li>
      </ul>
    </div>
  )
}

export default policyPage
