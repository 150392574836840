import { useEffect, useRef, useState } from "react"
import axios from "axios"
import Lottie from "lottie-react"
import {
  enlargeTextInput,
  getRandomInt,
  htmlDecode,
  requestTranslationToEnglish,
  requestTranslationToHebrew,
  urlify,
} from "./service"

import { ReactComponent as LogoGeko } from "./assets/logo-geko.svg"
import ActiveSoundGif from "./assets/sound-icon-active.json"
import { getSpeech } from "voice-rss-client/dist/browser"
import { ReactComponent as HeartIcon } from "./assets/heart-icon.svg"
import { ReactComponent as ActiveHeartIcon } from "./assets/active-heart-icon.svg"
import { ReactComponent as SpeakIcon } from "./assets/speak-icon.svg"

import bannerOneBi from "./assets/banner.png"

import { initializeApp } from "firebase/app"
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  doc,
  increment,
  updateDoc,
  serverTimestamp,
  getDoc,
  setDoc,
} from "firebase/firestore/lite"
import { getAnalytics } from "firebase/analytics"
import { firebaseConfig } from "./firebaseConfig"
import { Loader } from "./cmps/Loader"
import { InputForm } from "./cmps/InputForm"
import { Footer } from "./cmps/Footer"
import { Link } from "react-router-dom"
import AdSense from "react-adsense"

const firebaseApp = initializeApp(firebaseConfig)
const firebaseDb = getFirestore(firebaseApp)
getAnalytics(firebaseApp)

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
const mic = new SpeechRecognition()

mic.continuous = true
mic.interimResults = true
mic.lang = "he-IL"

var x = window.matchMedia("(max-width: 600px)")

export const MainPage = () => {
  const [question, setQuestion] = useState("")
  const [placeholder, setPlaceholder] = useState("שאלו אותי מה שרק תרצו...")
  const [isListening, setIsListening] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isPressedMic, setIsPressedMic] = useState(false)
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState([])
  const [isSpeaking, setIsSpeaking] = useState(false)
  const [audioPlayIndex, setAudioPlayIndex] = useState()

  const [loaderOpts, setLoaderOpts] = useState([])
  const [tags, setTags] = useState([])
  const isStillRecord = useRef()
  const RandomLoader = useRef()
  const audioPlayingRef = useRef()
  const currSession = useRef()

  useEffect(() => {
    const start = async () => {
      await getLoaders()
      await getTags()
      await handleSession()
    }
    start()
    sessionStorage.setItem("conversation", "")
    currSession.current = Date.now()

    return () => {
      clearTimeout(isStillRecord.current)
    }
  }, [])
  useEffect(() => {
    document.title = "עמוד ראשי"
  }, [])

  useEffect(() => {
    handleListen()
    clearTimeout(isStillRecord.current)
  }, [isListening])

  useEffect(() => {
    var objDiv = document.getElementsByClassName("answer-text")
    if (objDiv[0]) {
      objDiv[0].scrollTop = objDiv[0].scrollHeight
    }
  }, [answers])

  const getTags = async () => {
    let tagsFromDb = []
    const tagsCol = collection(firebaseDb, "Tags")
    const tagsSnapshot = await getDocs(tagsCol)

    tagsFromDb = tagsSnapshot.docs[0].data().tagsNames
    setTags(tagsFromDb)
  }

  async function getLogsLength() {
    const logsCol = collection(firebaseDb, "Settings")
    const logSnapshot = await getDocs(logsCol)
    return logSnapshot.docs[0].data().count
  }

  async function getLoaders() {
    let loadersFromDb = []
    const loaderCol = collection(firebaseDb, "Loaders")
    const loaderSnapshot = await getDocs(loaderCol)
    loaderSnapshot.docs.map((doc, index) => {
      loadersFromDb[index] = doc.data()
    })
    RandomLoader.current = getRandomInt(loadersFromDb.length)
    setLoaderOpts(loadersFromDb)
  }

  async function handleSession() {
    try {
      await setDoc(doc(firebaseDb, "sessions", "" + currSession.current), {
        browserPlatform: navigator.userAgentData?.platform ?? "",
        javaEnabled: navigator.javaEnabled() ?? "",
        browserVersion: navigator.userAgent ?? "",
        dataCookiesEnabled: navigator.cookieEnabled ?? "",
        dataCookies: document.cookie ?? "",
        sizeScreenW: window.screen.width ?? "",
        sizeScreenH: window.screen.height ?? "",
        screenColorDepth: window.screen.colorDepth ?? "",
        screenPixelDepth: window.screen.pixelDepth ?? "",

        createdAt: serverTimestamp(),
      })

      const counterFirebase = doc(firebaseDb, "sessions", "counter")
      await updateDoc(counterFirebase, {
        count: increment(1),
      })
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleListen = () => {
    if (isListening) {
      mic.start()
    } else {
      mic.stop()
    }

    mic.onspeechend = () => {
      generateAnswer()
      setQuestion("")
    }

    mic.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("")

      if (!transcript) return
      setQuestion(transcript)
      clearTimeout(isStillRecord.current)
      isStillRecord.current = setTimeout(() => {
        setIsListening(false)
      }, 1500)
      mic.onerror = (event) => {
        console.log(event.error)
      }
    }
  }

  const generateAnswer = async (ev, askQuestion = question, temp) => {
    if (temp > 0) temp = temp / 10
    if (ev) ev.preventDefault()
    clearTimeout(isStillRecord.current)
    setIsListening(false)
    if (!askQuestion) return

    var el = document.getElementsByClassName("question-input")
    if (el[0]) el[0].style.height = "42px"

    setPlaceholder("")
    try {
      setQuestion("")
      const questionNoQueMark = askQuestion.replace("?", "")
      const docRef = doc(firebaseDb, "CustomAnswers", questionNoQueMark.replace("/", ""))
      const docFireBase = await getDoc(docRef)

      let answerTranslate, isToEnglish, html, speakAnswer
      let conversation = sessionStorage.getItem("conversation")
      console.log(conversation)

      if (docFireBase.exists()) {
        const { answer } = docFireBase.data()

        setQuestions((prev) => [...prev, askQuestion])
        setIsLoading(true)
        const { id } = await addDoc(collection(firebaseDb, "Logs"), {
          answer: answer,
          question: askQuestion.replace("/", ""),
          createdAt: serverTimestamp(),
          sessionId: currSession.current,
          questionNumber: questions.length + 1,
        })
        setAnswers((prev) => [...prev, { answer: answer, id: id, isLike: false, speakAnswer: answer }])
        answerTranslate = answer
        RandomLoader.current = getRandomInt(loaderOpts.length)
        setIsLoading(false)
      } else {
        setQuestions((prev) => [...prev, askQuestion])
        setIsLoading(true)
        if (askQuestion.includes("לאנגלית") || askQuestion.includes("באנגלית")) isToEnglish = true

        const token = sessionStorage.getItem("tokenForGeko")
        const headers = {
          Authorization: `Bearer ${token}`,
        }

        var { data } = await axios.put(
          "/get-answer",
          {
            temp: temp,
            askQuestion: askQuestion,
            isToEnglish: isToEnglish,
            conversation: conversation,
          },
          {
            headers: headers,
          }
        )

        const { AnsFromAi, QuestionInEnglish, categoryText } = data
        let { answerTranslate } = data

        speakAnswer = answerTranslate
        answerTranslate = htmlDecode(answerTranslate)

        html = answerTranslate
        // html = urlify(answerTranslate)
        RandomLoader.current = getRandomInt(loaderOpts.length)
        setIsLoading(false)

        setQuestion("")
        sessionStorage.setItem(
          "conversation",
          `${conversation} Human: ${QuestionInEnglish} \n AI: ${AnsFromAi}`
        )

        const { id } = await addDoc(collection(firebaseDb, "Logs"), {
          answerInEnglish: AnsFromAi,
          questionInEnglish: QuestionInEnglish,
          answer: answerTranslate,
          question: askQuestion.replace("/", ""),
          createdAt: serverTimestamp(),
          sessionId: currSession.current,
          questionNumber: questions.length + 1,
          category: categoryText,
        })
        setAnswers((prev) => [
          ...prev,
          { answer: html, id: id, isLike: false, speakAnswer: speakAnswer },
        ])
      }
      const counterFirebase = doc(firebaseDb, "Settings", "counter")
      await updateDoc(counterFirebase, {
        count: increment(1),
      })
    } catch (error) {
      console.log(error)

      if (error.message === "Request failed with status code 403") {
        sessionStorage.removeItem("tokenForGeko")
        window.location.reload()
      }
    }
  }

  const backToHomePage = () => {
    sessionStorage.setItem("conversation", "")
    setQuestion("")
    setPlaceholder("שאלו אותי מה שרק תרצו...")
    setQuestions([])
    setAnswers([])
  }

  // const generateAnswer = async (ev, askQuestion = question, temp) => {
  //   if (temp > 0) temp = temp / 10
  //   if (ev) ev.preventDefault()
  //   clearTimeout(isStillRecord.current)
  //   setIsListening(false)
  //   if (!askQuestion) return

  //   setPlaceholder("")
  //   try {
  //     setQuestion("")
  //     const questionNoQueMark = askQuestion.replace("?", "")
  //     const docRef = doc(firebaseDb, "CustomAnswers", questionNoQueMark)
  //     const docFireBase = await getDoc(docRef)

  //     let answerTranslate, questionTranslate, isToEnglish, html, speakAnswer
  //     let conversation = sessionStorage.getItem("conversation")

  //     if (docFireBase.exists()) {
  //       const { answer } = docFireBase.data()

  //       setQuestions((prev) => [...prev, askQuestion])
  //       setIsLoading(true)
  //       const { id } = await addDoc(collection(firebaseDb, "Logs"), {
  //         answer: answer,
  //         question: askQuestion,
  //         createdAt: serverTimestamp(),
  //         sessionId: currSession.current,
  //         questionNumber: questions.length + 1,
  //       })
  //       setAnswers((prev) => [...prev, { answer: answer, id: id, isLike: false, speakAnswer: answer }])
  //       answerTranslate = answer
  //       RandomLoader.current = getRandomInt(loaderOpts.length)
  //       setIsLoading(false)
  //     } else {
  //       setQuestions((prev) => [...prev, askQuestion])
  //       setIsLoading(true)
  //       if (askQuestion.includes("לאנגלית") || askQuestion.includes("באנגלית")) isToEnglish = true
  //       questionTranslate = await requestTranslationToEnglish(askQuestion)
  //       var { data } = await axios.put("/ask-ai", {
  //         text: `${conversation} Human: ${questionTranslate}`,
  //         temp: temp,
  //       })

  //       const category = await axios.put("/get-category", {
  //         text: questionTranslate,
  //       })
  //       category.data = category.data.replace("\n", "")

  //       if (data.charAt(0) === "?") {
  //         data = data.substring(1)
  //       }
  //       data = data.replace("AI:", "")
  //       if (isToEnglish) answerTranslate = data
  //       else answerTranslate = await requestTranslationToHebrew(data)
  //       answerTranslate = answerTranslate.replace("רובוט:", "")
  //       answerTranslate = answerTranslate.replace("מחשב:", "")
  //       answerTranslate = answerTranslate.replace("בוט:", "")
  //       speakAnswer = answerTranslate
  //       answerTranslate = htmlDecode(answerTranslate)

  //       html = answerTranslate
  //       // html = urlify(answerTranslate)
  //       RandomLoader.current = getRandomInt(loaderOpts.length)
  //       setIsLoading(false)

  //       setQuestion("")
  //       sessionStorage.setItem("conversation", `${conversation} Human: ${askQuestion} ${data}`)
  //       const { id } = await addDoc(collection(firebaseDb, "Logs"), {
  //         answerInEnglish: data,
  //         questionInEnglish: questionTranslate,
  //         answer: answerTranslate,
  //         question: askQuestion,
  //         createdAt: serverTimestamp(),
  //         sessionId: currSession.current,
  //         questionNumber: questions.length + 1,
  //         category: category.data,
  //       })
  //       setAnswers((prev) => [
  //         ...prev,
  //         { answer: html, id: id, isLike: false, speakAnswer: speakAnswer },
  //       ])
  //     }
  //     const counterFirebase = doc(firebaseDb, "Settings", "counter")
  //     await updateDoc(counterFirebase, {
  //       count: increment(1),
  //     })
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const handleChange = ({ target }) => {
    enlargeTextInput()
    let removeScript
    if (target.value) {
      removeScript = target.value.replace("<script>", "")
      removeScript = removeScript.replace("</script>", "")
      removeScript = removeScript.replace("<script/>", "")
    }
    setQuestion(removeScript)
  }

  const handleMicClick = async () => {
    setIsListening((prevState) => !prevState)

    if (!isPressedMic) setIsPressedMic(true)

    const counterFirebase = doc(firebaseDb, "Settings", "micPress")
    await updateDoc(counterFirebase, {
      count: increment(1),
    })
  }

  const likeAnswer = async (answer, index) => {
    let newAnswers = [...answers]
    try {
      const docRef = doc(firebaseDb, "Logs", answer.id)
      await updateDoc(docRef, {
        isLike: !answer.isLike,
      })
      newAnswers[index] = { ...newAnswers[index], isLike: !answer.isLike }

      setAnswers(newAnswers)
    } catch (error) {
      console.log(error)
    }
  }

  const speakAnswer = async (text, index) => {
    if (index === audioPlayIndex && isSpeaking && audioPlayingRef.current) {
      audioPlayingRef.current.pause()
      setIsSpeaking(false)
      return
    }

    if (index === audioPlayIndex && !isSpeaking && audioPlayingRef.current) {
      audioPlayingRef.current.play()
      setIsSpeaking(true)
      return
    }

    if (index !== audioPlayIndex && isSpeaking && audioPlayingRef.current) {
      audioPlayingRef.current.pause()
    }

    if (audioPlayingRef.current) {
      audioPlayingRef.current.pause()
      audioPlayingRef.current = null
    }

    let speech = await getSpeech({
      apiKey: "4a37b9e6a3ae4a2596a6ba8f6275fe49",
      language: "he-il",
      text: text,
    })

    speech = speech.slice(0, 5) + "audio/wav" + speech.slice(5)
    audioPlayingRef.current = new Audio(speech)
    audioPlayingRef.current.play()
    setAudioPlayIndex(index)
    setIsSpeaking(true)

    audioPlayingRef.current.addEventListener("ended", function () {
      audioPlayingRef.current = null
      setIsSpeaking(false)
    })
  }
  const saveQues = async (ques, ans, index, id) => {
    const tagsElement = document.getElementById(`tags-text-area-${index}`)
    let tags = tagsElement.value.split(",")
    tags.forEach((tag, index) => {
      const trimmedTag = tag.trimStart()
      tags[index] = trimmedTag.trimEnd()
    })

    const whoEnteredElemENT = document.getElementById(`who-entered-${index}`)

    if (!ques || !ans || !tags || !whoEnteredElemENT.value) {
      alert("מלא את כל הערכים בבקשה")
      return
    }

    try {
      await setDoc(doc(firebaseDb, "ExampleQA", ques), {
        answer: ans,
        tags: tags,
        whoSaved: whoEnteredElemENT.value,
        createdAt: serverTimestamp(),
      })

      const questionRef = doc(firebaseDb, "Logs", id)

      await updateDoc(questionRef, {
        isSaved: true,
      })
      alert("נשמר בהצלחה")
    } catch (error) {
      alert(error.message)
    }
  }

  const updateClicksDb = async (tag) => {
    const tagsClicksRef = doc(firebaseDb, "ClickInteractionsTags", "counters")

    await updateDoc(tagsClicksRef, {
      [tag]: increment(1),
    })

    await addDoc(collection(firebaseDb, "ClickInteractionsTags"), {
      when: serverTimestamp(),
      nameOfTheTag: tag,
    })
  }

  const handleScreenInteraction = async (onWhatPressed) => {
    const screenClickRef = doc(firebaseDb, "ClicksScreenInteractions", "counters")
    await updateDoc(screenClickRef, {
      [onWhatPressed]: increment(1),
    })

    await addDoc(collection(firebaseDb, "ClicksScreenInteractions"), {
      when: serverTimestamp(),
      nameOfTheObject: onWhatPressed,
    })
  }

  return (
    <div className="app">
      {/* {!questions.length > 0 && (
        <>
          <script
            type="text/javascript"
            src="https://track.clickon.co.il/sz/FHZQx45TxZsYKxn/s?noref=1"
          ></script>
          <iframe
            src="https://track.clickon.co.il/sz/FHZQx45TxZsYKxn/h?noref=1"
            width="320"
            height="50"
            frameBorder="0"
            className="ad-for-mobile top"
          ></iframe>
        </>
      )} */}
      {/* {!questions.length > 0 && (
        <img
          className="ad-for-mobile top"
          src={bannerOneBi}
          alt="פרסומת לאנשי דאטה וואן בי אי"
          tabIndex="0"
        />
      )} */}
      {/* <ins
        class="adsbygoogle"
        data-ad-layout-key="-gw-3+1f-3d+2z"
        style={{ display: "block" }}
        data-ad-client="ca-pub-23452425"
        data-ad-slot="24524524"
        data-ad-format="auto"
      ></ins> */}
      {/* <div className="ad-for-desktop left"></div>
      <div className="ad-for-desktop right"></div>
      <div className="ad-for-desktop top"></div>
      <div className="ad-for-mobile top"></div> */}
      <div
        onClick={() => handleScreenInteraction("linkToOneBi")}
        className={questions.length > 0 ? "logo-container with-ans" : "logo-container"}
      >
        {!questions.length > 0 && (
          <a
            className="logo-link-container"
            href="https://onebi.co.il/"
            target="_blank"
            alt="לוגו מוביל לאתר one bi"
            title="לוגו מוביל לאתר one bi"
          >
            <LogoGeko alt="לוגו מוביל לאתר one bi" className="logo" title="לוגו מוביל לאתר one bi" />
          </a>
        )}
        {questions.length > 0 && (
          <div onClick={backToHomePage} className="logo-link-container">
            <LogoGeko alt="חזרה לעמוד הראשי" tabIndex="0" className="logo" title="חזרה לעמוד הראשי" />
          </div>
        )}
      </div>
      {!questions.length > 0 && (
        <>
          <h2 className="explanation-text">הי אני geko, אתר מבוסס בינה מלאכותית! </h2>
          <h2 className="explanation-text">
            אני יכול לנהל שיחה, לכתוב ברכות, לענות תשובות לשאלות מורכבות ואפילו להמליץ על טיולים.
          </h2>
          <h2 className="explanation-text"> לצערי אני לא מעודכן באקטואליה מהשנתיים האחרונות</h2>

          {/* <Link
            style={{ textDecoration: "none", width: "fit-content", margin: " 20px auto 0" }}
            className="tag-bubble"
            to={`/passover`}
          >
            להתייעץ עם הרב גקו לפסח
          </Link> */}
        </>
      )}
      <div className={questions.length > 0 ? "ans-que-container" : "question-container"}>
        <div className="answer-text">
          {answers.length > 0 &&
            answers.map((answer, index) => (
              <div className="single-ans-que-container" key={answer.id + index}>
                {/* <AdSense.Google
                  client="ca-pub-7544307431332675"
                  slot="3731950191"
                  style={{
                    width: x.matches ? "250px" : "90%",
                    height: x.matches ? "20%" : "45%",
                    alignSelf: "center",
                    // position: "relative",
                    // left: "-10px",
                  }}
                  layout="in-article"
                  format="fluid"
                /> */}
                {<h4 className="old-question-text">{questions[index]}</h4>}
                {isSpeaking && audioPlayIndex === index ? (
                  <Lottie
                    animationData={ActiveSoundGif}
                    loop={true}
                    className="speak-icon active"
                    onClick={() => {
                      speakAnswer(answer.speakAnswer, index)
                      handleScreenInteraction("stopReadingIcon")
                    }}
                    alt="עצור הקראה"
                    title="עצור הקראה"
                    tabIndex="0"
                    onKeyUp={(ev) => {
                      if (ev.keyCode === 13) {
                        speakAnswer(answer.speakAnswer, index)
                        handleScreenInteraction("stopReadingIcon")
                      }
                    }}
                  />
                ) : (
                  <SpeakIcon
                    onClick={() => {
                      speakAnswer(answer.speakAnswer, index)
                      handleScreenInteraction("startReadingIcon")
                    }}
                    className="speak-icon"
                    alt="התחל הקראה"
                    title="התחל הקראה"
                    tabIndex="0"
                    onKeyUp={(ev) => {
                      if (ev.keyCode === 13) {
                        speakAnswer(answer.speakAnswer, index)
                        handleScreenInteraction("startReadingIcon")
                      }
                    }}
                  />
                )}
                <div dangerouslySetInnerHTML={{ __html: answer.answer }} className="answer"></div>
                {/* <p>תגים</p>
                <textarea id={`tags-text-area-${index}`}></textarea>
                <input type="text" id={`who-entered-${index}`} placeholder="...מה השם שלך" />
                <button onClick={() => saveQues(questions[index], answer.speakAnswer, index, answer.id)}>
                  שמור
                </button> */}
                {!answer.isLike && (
                  <HeartIcon
                    onClick={() => {
                      likeAnswer(answer, index)
                      handleScreenInteraction("likeAnswerIcon")
                    }}
                    className="like-icon-answer"
                    tabIndex="0"
                    alt="סמן תשובה כאהבתי"
                    title="סמן תשובה כאהבתי"
                    onKeyUp={(ev) => {
                      if (ev.keyCode === 13) {
                        likeAnswer(answer, index)
                        handleScreenInteraction("likeAnswerIcon")
                      }
                    }}
                  />
                )}
                {answer.isLike && (
                  <ActiveHeartIcon
                    onClick={() => {
                      likeAnswer(answer, index)
                      handleScreenInteraction("dislikeAnswerIcon")
                    }}
                    className="like-icon-answer"
                    tabIndex="0"
                    alt="בטל סימון תשובה כאהבתי"
                    title="בטל סימון תשובה כאהבתי"
                    onKeyUp={(ev) => {
                      if (ev.keyCode === 13) {
                        likeAnswer(answer, index)
                        handleScreenInteraction("dislikeAnswerIcon")
                      }
                    }}
                  />
                )}
              </div>
            ))}
        </div>
        {loaderOpts.length > 0 && (
          <Loader
            loaderOpts={loaderOpts}
            isLoading={isLoading}
            RandomLoader={RandomLoader}
            questions={questions}
          />
        )}

        <div className={questions.length > 0 ? "center-section stick-to-bottom" : "center-section"}>
          <InputForm
            generateAnswer={generateAnswer}
            handleScreenInteraction={handleScreenInteraction}
            handleMicClick={handleMicClick}
            isListening={isListening}
            question={question}
            handleChange={handleChange}
            placeholder={placeholder}
          />
        </div>
        {!questions.length > 0 && <h2 className="tags-title">תראו על מה שאלו אותי…</h2>}
        {!questions.length > 0 && tags && (
          <div className="tags-container">
            {tags.map((tag) => (
              <Link
                style={{ textDecoration: "none", width: "unset" }}
                key={tag}
                to={`/ques-examples/${tag}`}
              >
                <div onClick={() => updateClicksDb(tag)} className="tag-bubble">
                  {tag}
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
      <Footer
        questions={questions}
        setQuestion={setQuestion}
        getLogsLength={getLogsLength}
        handleScreenInteraction={handleScreenInteraction}
      />
    </div>
  )
}
