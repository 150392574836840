import "./aboutUs.scss"

export const AboutUs = ({ setAboutUsOpen }) => {
  return (
    <div className="about-us-section">
      <h2>אודות geko</h2>
      <p>
        תמיד חשבתם איזה כיף יהיה לנהל שיחה עם מישהו שיודע לענות לי על כמעט הכל ובכלום זמן? עכשיו זה קורה!
        הכירו את גקו הבוט החכם הראשון בעברית. אתם יכולים לבקש ממנו לספר לכם סיפור, לעזור לכם לכתוב ברכות
        לקרובים שלכם, להמליץ על יעדים שונים, לסייע בשיעורי הבית, לשאול כל שאלה שאף פעם לא העזתם לשאול או
        סתם לנהל איתו שיחת חולין שמי יודע לאן היא תתפתח.
      </p>
      <h4>מי אנחנו?</h4>
      <p>
        {" "}
        <a href="https://onebi.co.il/" target="_blank">
          One bi
        </a>{" "}
        חטיבת הדאטה והאנליטיקה של קבוצת{" "}
        <a href="https://onetechnologies.net/" target="_blank">
          {" "}
          ONE technologies
        </a>
        , בעלת ניסיון רב של יותר מ-15 שנים. אנחנו מתמחים בתכנון וביצוע פרויקטים דאטה מורכבים על ידי מתן
        שירותים שאין שני להם באסטרטגיה, ייעוץ, טכנולוגיה ותפעול. אנחנו משתפים פעולה עם למעלה מ-250 חברות
        גדולות ובינוניות, המניעות חדשנות כדי לשפר את הדרך שבה הן פועלות ואת תהליכי קבלת ההחלטות שלהן.
        במקביל אנחנו חברה שואפים כל הזמן לבנות תרבות שבה אנשים גדלים, מתפתחים ומקבלים השראה ללמוד. בתוך
        פעילות{" "}
        <a href="https://onebi.co.il/" target="_blank">
          One bi
        </a>{" "}
        נמצאות גם פעילות{" "}
        <a href="https://saban.marketing/" target="_blank">
          {" "}
          Digital Marketing
        </a>{" "}
        ו הרובוטיקה{" "}
        <a href="https://www.onerobotix.co.il/" target="_blank">
          {" "}
          one robotix
        </a>{" "}
        של קבוצת{" "}
        <a href="https://onetechnologies.net/" target="_blank">
          {" "}
          ONE technologies
        </a>
        .
      </p>
      <h4>איך geko נולד?</h4>
      <p>
        מאז ומתמיד עניין אותנו להתעסק עם עיבוד שפה טבעית, וכש-open AI החלו לחשוף את הכלים שלהם לציבור
        הרחב, התחלנו לחקור את הגבולות של הטכנולוגיה וכך בא הרעיון להנגיש את היכולות לתצורה של בוט ידידותי
        שיודע לנהל שיחה רציפה עם כל אחד.
      </p>
      <h4>מה הטכנולוגיה שעומדת מאחורי geko?</h4>
      <p>
        Open AI המתמקדת בחקירת בינה מלאכותית פיתוחו את מגנון GPT-3. למגנון יש API והוא חלק ממגמה הולך
        ומתגברת של מנגנונים לעיבוד שפה טבעית NLP(Natural Language processing). לגרסה המלאה של GPT-3
        קיבולת של 175 מיליארד פרמטרים של למידת מכונה.
      </p>

      <p onClick={() => setAboutUsOpen(false)} style={{ marginTop: "20px", cursor: "pointer" }}>
        אז למה אתם מחכים בואו ותתחילו איתו שיחה {">>"}
      </p>
    </div>
  )
}
