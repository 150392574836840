import microphoneWork from "../assets/microphone-work.png"
import { ReactComponent as InputTextLogo } from "../assets/input-text-logo.svg"
import SearchGif from "../assets/search-gif.json"
import { ReactComponent as MicLogo } from "../assets/mic-logo.svg"
import bannerOneBi from "../assets/banner.png"
import "./inputForm.scss"

import Lottie from "lottie-react"
import { iOS } from "../service"
import { useState } from "react"

const winNav = window.navigator
const isIOSChrome = winNav.userAgent.match("CriOS")

const isFromIphone = iOS()

export const InputForm = ({
  generateAnswer,
  handleMicClick,
  isListening,
  question,
  handleChange,
  placeholder,
  handleScreenInteraction,
}) => {
  const [rate, setRate] = useState(9)

  const handleMicEnter = (ev) => {
    if (ev.keyCode === 13) handleMicClick()
  }

  // const addRate = (num) => {
  //   if (typeof num !== "number" || isNaN(num)) {
  //     setRate(0)
  //   } else {
  //     setRate(num)
  //   }
  // }

  return (
    <>
      <form className="question-input-form" onSubmit={(ev) => generateAnswer(ev, undefined, rate)}>
        <div className="input-container">
          {isListening ? (
            <img
              src={microphoneWork}
              className="mic-logo"
              onKeyUp={(ev) => {
                handleMicEnter(ev)
                handleScreenInteraction("stopRecordIcon")
              }}
              onClick={(ev) => {
                handleMicClick(ev)
                handleScreenInteraction("stopRecordIcon")
              }}
              alt="מקליט לחץ להפסק הקלטה"
              title="מקליט לחץ להפסק הקלטה"
              tabIndex="0"
            />
          ) : question || isIOSChrome ? (
            <Lottie
              animationData={SearchGif}
              loop={true}
              className="mic-logo"
              alt="חפש"
              title="חפש"
              tabIndex="0"
              onClick={(ev) => {
                generateAnswer(ev, undefined, rate)
                handleScreenInteraction("askGekoIcon")
              }}
              onKeyUp={(ev) => {
                if (ev.keyCode === 13) {
                  generateAnswer(ev)
                  handleScreenInteraction("askGekoIcon")
                }
              }}
            />
          ) : (
            <MicLogo
              className="mic-logo"
              onClick={(ev) => {
                handleMicClick(ev)
                handleScreenInteraction("startRecordIcon")
              }}
              title="לחץ להתחלת הקלטה"
              alt="לחץ להתחלת הקלטה"
              tabIndex="0"
              onKeyUp={(ev) => {
                handleMicEnter(ev)
                handleScreenInteraction("startRecordIcon")
              }}
            />
          )}
          {/* {!isFromIphone && (
      <textarea
        alt="הזן טקסט כאן"
        className="question-input"
        type="text"
        placeholder={placeholder}
        value={question}
        onChange={handleChange}
      ></textarea>
    )} */}
          {/* {isFromIphone && ( */}
          <input
            onClick={() => handleScreenInteraction("EnterQuestionInput")}
            alt="הזן טקסט כאן להתחלת שיחה"
            title="הזן טקסט כאן להתחלת שיחה"
            className="question-input-iphone"
            type="text"
            placeholder={placeholder}
            value={question}
            onChange={handleChange}
          />
          {/* )} */}
          <InputTextLogo className="question-input-img" />
        </div>
      </form>
      {/* <a href="https://onebi.co.il/join-us" target="_blank">
        <img
          className="ad-for-desktop-new marginTop40"
          src={bannerOneBi}
          alt="פרסומת לאנשי דאטה וואן בי אי"
          tabIndex="0"
        />
      </a> */}
      {/* <>
        <script
          type="text/javascript"
          src="https://track.clickon.co.il/sz/NHe12jCCbxJp1eU/s?noref=1"
        ></script>
        <iframe
          src="https://track.clickon.co.il/sz/NHe12jCCbxJp1eU/h?noref=1"
          width="728"
          height="90"
          frameBorder="0"
          className="ad-for-desktop-new marginTop40"
        ></iframe>
      </> */}
      {/* <a href="https://onebi.co.il/join-us" target="_blank">
        <img
          className="ad-for-mobile top marginTop40"
          src={bannerOneBi}
          alt="פרסומת לאנשי דאטה וואן בי אי"
          tabIndex="0"
        />
      </a> */}
      {/* <>
        <script
          type="text/javascript"
          src="https://track.clickon.co.il/sz/FHZQx45TxZsYKxn/s?noref=1"
        ></script>
        <iframe
          src="https://track.clickon.co.il/sz/FHZQx45TxZsYKxn/h?noref=1"
          width="320"
          height="50"
          frameBorder="0"
          className="ad-for-mobile top marginTop40"
        ></iframe>
      </> */}

      {/* <div style={{ marginTop: "50px" }} className="temp-btn-container">
        <label htmlFor="temp-input">בחר רמת יצירתיות</label>
      </div>

      <div class="container">
        <div class="range-slider">
          <span
            // style={{
            //   left: window.matchMedia("(max-width: 600px)").matches
            //     ? (rate * (window.screen.width - 100)) / 10 + "px"
            //     : rate * 17.8 + "px",
            // }}
            style={{ left: rate * 17.8 + "px" }}
            id="rs-bullet"
            class="rs-label"
          >
            {rate}
          </span>
          <input
            id="rs-range-line"
            class="rs-range"
            type="range"
            onChange={({ target }) => setRate(target.value)}
            value={rate}
            min={0}
            max={10}
          />
        </div>

        <div class="box-minmax">
          <span>0</span>
          <span>10</span>
        </div>
      </div> */}
    </>
  )
}
