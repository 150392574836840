import axios from "axios"

export const htmlDecode = (input) => {
  var doc = new DOMParser().parseFromString(input, "text/html")
  return doc.documentElement.textContent
}

export const urlify = (text) => {
  const myArray = text.split(" ")
  // myArray.forEach((word, index) => {
  //   if (word.includes(".com") || word.includes(".co.il")) {
  //     const char = word.charAt(word.length - 1)
  //     if (char !== "m" || char !== "l") word = word.slice(0, -1)
  //     if (!word.includes("https:")) word = "https://" + word
  //     myArray[index] = word
  //   }
  // })
  text = myArray.join(" ")
  var urlRegexAHref = /(https?:\/\/[^\s]+)/g
  return text.replace(urlRegexAHref, function (url) {
    return '<a target="_blank" href="' + url + '">' + url.replace("https://", "") + "</a>"
  })
}

export const makeId = () => {
  const length = 12
  var result = ""
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const requestTranslationToEnglish = async (textEnglish) => {
  const { data } = await axios.put("/translate-to-english", {
    text: textEnglish,
  })
  return data
}

export const iOS = () => {
  return (
    ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
      navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  )
}

export const requestTranslationToHebrew = async (textEnglish) => {
  const { data } = await axios.put("/translate-to-hebrew", {
    text: textEnglish,
  })
  return data
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * max)
}

export const enlargeTextInput = () => {
  var el = document.getElementsByClassName("question-input")
  if (el[0]) {
    el[0].style.height = "5px"
    if (el[0].scrollHeight) {
      el[0].style.height = el[0].scrollHeight + "px"

      var mic = document.getElementsByClassName("mic-logo")
      if (mic[0]) {
        if (el[0].scrollHeight === 82) {
          mic[0].style.top = "29px"
        }
        if (el[0].scrollHeight === 73) {
          mic[0].style.top = "29px"
        }
        if (el[0].scrollHeight === 57) {
          mic[0].style.top = "21px"
        }
        if (el[0].scrollHeight === 51) {
          mic[0].style.top = "19px"
        }

        if (el[0].scrollHeight === 42) {
          mic[0].style.top = "14px"
        }
      }
    }
  }
}
