import { useEffect, useState } from "react"
import { numberWithCommas } from "../service"
import "./footer.scss"
import { Link } from "react-router-dom"
import { AboutUs } from "../pages/AboutUs"

export const Footer = ({ questions, setQuestion, getLogsLength, handleScreenInteraction }) => {
  const [howManyLogs, setHowManyLogs] = useState(null)
  const [aboutUsOpen, setAboutUsOpen] = useState(false)

  useEffect(() => {
    const start = async () => {
      const length = await getLogsLength()
      setHowManyLogs(length)
    }
    start()
  }, [])

  return (
    <div className={questions.length > 0 ? "footer-to-bottom" : "footer"}>
      {!questions.length > 0 && howManyLogs && (
        <h4 className="questions-so-far-text">כמה שאלות שאלו אותי : {numberWithCommas(howManyLogs)}</h4>
      )}

      {!questions.length > 0 && (
        <h6 className="gpt-desc-title">
          OPEN.AI של
          <span
            className="gpt-3-span"
            alt="שאל מה זה GPT-3"
            title="שאל מה זה GPT-3"
            tabIndex="0"
            onKeyUp={(ev) => {
              if (ev.keyCode === 13) {
                setQuestion("מה זה GPT-3?")
                handleScreenInteraction("WhatIsGPT3")
              }
            }}
            onClick={() => {
              setQuestion("מה זה GPT-3?")
              handleScreenInteraction("WhatIsGPT3")
            }}
          >
            {" "}
            GPT-3{" "}
          </span>
          זה מבוסס על צ'אט ai אתר
        </h6>
      )}

      <p className={questions.length > 0 ? "beta-version-text margin0" : "beta-version-text"}>
        Beta version
      </p>

      <div
        style={{ width: questions.length > 0 && "unset" }}
        className={questions.length > 0 ? "links-container margin0" : "links-container"}
      >
        <Link
          onClick={() => {
            handleScreenInteraction("OpenPolicy")
          }}
          to="/policy"
          target="_blank"
          title="פתיחת התקנון"
          className="policy-link"
          alt="פתיחת התקנון"
        >
          תקנון שימוש באתר
        </Link>
        {!questions.length > 0 && (
          <p
            onClick={() => {
              setAboutUsOpen((prev) => !prev)
              handleScreenInteraction(aboutUsOpen ? "closeAboutUs" : "openAboutUs")
            }}
            alt="פתיחה / סגירה אודות"
            title="פתיחה / סגירה אודות"
            className="about-us"
            tabIndex="0"
            onKeyUp={(ev) => {
              if (ev.keyCode === 13) {
                setAboutUsOpen((prev) => !prev)
                handleScreenInteraction(aboutUsOpen ? "closeAboutUs" : "openAboutUs")
              }
            }}
          >
            אודות
          </p>
        )}
        {!questions.length > 0 && (
          <a
            style={{ width: "unset" }}
            href="https://onebi.co.il/#ready-section"
            target="_blank"
            alt="הישלח לעמוד ליצירת קשר"
            title="הישלח לעמוד ליצירת קשר"
            onClick={() => {
              handleScreenInteraction("contactUsIcon")
            }}
          >
            צור קשר
          </a>
        )}
      </div>
      {aboutUsOpen && !questions.length > 0 && <AboutUs setAboutUsOpen={setAboutUsOpen} />}
    </div>
  )
}
