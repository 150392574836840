import { ReactComponent as LightBolb } from "../assets/light-bolb.svg"
import "./loader.scss"
import bannerOneBi from "../assets/banner.png"

export const Loader = ({ isLoading, loaderOpts, RandomLoader, questions }) => {
  // isLoading = true
  var x = window.matchMedia("(max-width: 600px)")
  return (
    <div className={isLoading ? "loader-full-screen" : "dont-show"}>
      <div className={isLoading ? "loader-container" : "dont-show"}>
        <span className={isLoading ? "loader" : "dont-show"} />
        <div className={isLoading ? "loader-data-container" : "dont-show"}>
          {/* {questions.length > 1 && x.matches && (
            // <img
            //   className="ad-for-mobile top"
            //   src={bannerOneBi}
            //   alt="פרסומת לאנשי דאטה וואן בי אי"
            //   tabIndex="0"
            //   style={{ height: "unset" }}
            // />
            <>
              <script
                type="text/javascript"
                src="https://track.clickon.co.il/sz/FHZQx45TxZsYKxn/s?noref=1"
              ></script>
              <iframe
                src="https://track.clickon.co.il/sz/FHZQx45TxZsYKxn/h?noref=1"
                width="320"
                height="50"
                frameBorder="0"
                className="ad-for-mobile top"
              ></iframe>
            </>
          )} */}
          {/* {questions.length > 1 && !x.matches && (
            // <img
            //   className="ad-for-desktop-new"
            //   src={bannerOneBi}
            //   alt="פרסומת לאנשי דאטה וואן בי אי"
            //   tabIndex="0"
            //   style={{ height: "unset" }}
            // />
            <>
              <script
                type="text/javascript"
                src="https://track.clickon.co.il/sz/NHe12jCCbxJp1eU/s?noref=1"
              ></script>
              <iframe
                src="https://track.clickon.co.il/sz/NHe12jCCbxJp1eU/h?noref=1"
                width="728"
                height="90"
                frameBorder="0"
                className="ad-for-desktop-new"
              ></iframe>
            </>
          )} */}
          {/* {questions.length <= 1 && ( */}
          <div className={isLoading ? "loader-text-container" : "dont-show"}>
            <div className={isLoading ? "did-you-know-container" : "dont-show"}>
              <p
                className={isLoading ? "" : "dont-show"}
                style={{ color: "#7d7d7d", marginRight: "5px" }}
              >
                ?הידעת
              </p>
              <LightBolb />
            </div>
            <p className={isLoading ? "loader-text" : "dont-show"}>
              {loaderOpts[RandomLoader.current].text}
            </p>
          </div>
          {/* )} */}
          {/* {questions.length <= 1 && ( */}
          <img
            className={isLoading ? "" : "dont-show"}
            src={loaderOpts[RandomLoader.current].imgurl}
            alt="לואדר"
            tabIndex="0"
          />
          {/* // )} */}
        </div>
      </div>
    </div>
  )
}

// import { ReactComponent as LightBolb } from "../assets/light-bolb.svg"
// import "./loader.scss"

// export const Loader = ({ isLoading, loaderOpts, RandomLoader, questions }) => {
//   // isLoading = true
//   var x = window.matchMedia("(max-width: 600px)")
//   return (
//     <div className={isLoading ? "loader-full-screen" : "dont-show"}>
//       <div className={isLoading ? "loader-container" : "dont-show"}>
//         <span className={isLoading ? "loader" : "dont-show"} />
//         <div className={isLoading ? "loader-data-container" : "dont-show"}>
//           {/* {questions.length > 1 && x.matches && <div className="ad-for-mobile loader-ad"></div>} */}
//           {/* {(questions.length <= 1 || !x.matches) && ( */}
//           <div className={isLoading ? "loader-text-container" : "dont-show"}>
//             <div className={isLoading ? "did-you-know-container" : "dont-show"}>
//               <p
//                 className={isLoading ? "" : "dont-show"}
//                 style={{ color: "#7d7d7d", marginRight: "5px" }}
//               >
//                 ?הידעת
//               </p>
//               <LightBolb />
//             </div>
//             <p className={isLoading ? "loader-text" : "dont-show"}>
//               {loaderOpts[RandomLoader.current].text}
//             </p>
//           </div>
//           {/* )} */}
//           {/* {(questions.length <= 1 || !x.matches) && ( */}
//           <img
//             className={isLoading ? "" : "dont-show"}
//             src={loaderOpts[RandomLoader.current].imgurl}
//             alt="לואדר"
//           />
//           {/* // )} */}
//         </div>
//       </div>
//     </div>
//   )
// }
