import { useEffect, useState } from "react"

import { initializeApp } from "firebase/app"
import { firebaseConfig } from "../firebaseConfig"

import { ReactComponent as LogoGeko } from "../assets/logo-geko.svg"
import { ReactComponent as Arrow } from "../assets/arrow.svg"
import { ReactComponent as SideArrow } from "../assets/side-arrow.svg"

import "./quesExamples.scss"
import { Link, useLocation } from "react-router-dom"
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore"
import { htmlDecode } from "../service"

export const QuesExamples = () => {
  const firebaseApp = initializeApp(firebaseConfig)
  const firebaseDb = getFirestore(firebaseApp)
  const location = useLocation()
  const [examples, setExamples] = useState(null)
  const [examplesToShow, setExamplesToShow] = useState([])
  const [tagChosen, setTagChosen] = useState("")
  const [filterInput, setFilterInput] = useState("")

  useEffect(() => {
    const start = async () => {
      await getData()
    }
    start()

    let tagChosenUrl = location.pathname
    tagChosenUrl = tagChosenUrl.substring(15)
    tagChosenUrl = decodeURI(tagChosenUrl)
    setTagChosen(tagChosenUrl)
  }, [])

  useEffect(() => {
    if (examples) {
      let filteredExamples = [...examples]
      filteredExamples = filteredExamples.filter((str) => str.question.includes(filterInput))
      setExamplesToShow([...filteredExamples])
    }
  }, [filterInput])

  const getData = async () => {
    let examplesDb = []

    let tagChosenUrl = location.pathname
    tagChosenUrl = tagChosenUrl.substring(15)
    tagChosenUrl = decodeURI(tagChosenUrl)

    const examplesRef = collection(firebaseDb, "ExampleQA")
    const q = query(examplesRef, where("tags", "array-contains", tagChosenUrl))
    const examplesSnapshot = await getDocs(q)

    examplesSnapshot.docs.forEach((doc, index) => {
      examplesDb[index] = { question: doc.id, answer: htmlDecode(doc.data().answer), isShown: false }
    })
    setExamples([...examplesDb])
    setExamplesToShow([...examplesDb])
  }

  const toggleAnsShow = (questionStr) => {
    let examplesHolder = [...examples]
    let examplesToShowHolder = [...examplesToShow]

    let singleExampleIndex = examplesHolder.findIndex((example) => example.question === questionStr)
    examplesHolder[singleExampleIndex] = {
      ...examplesHolder[singleExampleIndex],
      isShown: !examplesHolder[singleExampleIndex].isShown,
    }

    let singleExampleShowIndex = examplesToShowHolder.findIndex(
      (example) => example.question === questionStr
    )
    examplesToShowHolder[singleExampleShowIndex] = {
      ...examplesToShowHolder[singleExampleShowIndex],
      isShown: !examplesToShowHolder[singleExampleShowIndex].isShown,
    }
    setExamplesToShow([...examplesToShowHolder])
    setExamples([...examplesHolder])
  }

  return (
    <div className="ques-examples-page">
      <div className="header">
        <Link to="/" title="חזור לעמוד הבית" className="logo-container" alt="חזור לעמוד הבית">
          <div className="logo-container with-ans">
            <LogoGeko alt="לוגו מוביל לאתר one bi" title="לוגו מוביל לאתר one bi" />
          </div>
        </Link>
      </div>
      <h1>שאלות בנושא {tagChosen}</h1>
      <input
        type="text"
        placeholder="חיפוש..."
        className="search-examples-input"
        alt={`חיפוש שאלה בקטוגריית ${tagChosen}`}
        title={`חיפוש שאלה בקטוגריית ${tagChosen}`}
        value={filterInput}
        onChange={({ target }) => setFilterInput(target.value)}
      />
      {examplesToShow &&
        examplesToShow.map((example) => (
          <div key={example.question} className="single-ques-ans">
            <h6
              style={{ cursor: "pointer" }}
              onClick={() => toggleAnsShow(example.question)}
              className="question"
            >
              {example.question}
              <span onClick={() => toggleAnsShow(example.question)}>
                {!example.isShown ? (
                  <SideArrow
                    alt="ראה תשובה"
                    title="ראה תשובה"
                    tabIndex="0"
                    onKeyUp={(ev) => {
                      if (ev.keyCode === 13) toggleAnsShow(example.question)
                    }}
                  />
                ) : (
                  <Arrow
                    alt="סגור תשובה"
                    title="סגור תשובה"
                    tabIndex="0"
                    onKeyUp={(ev) => {
                      if (ev.keyCode === 13) toggleAnsShow(example.question)
                    }}
                  />
                )}
              </span>
            </h6>
            {example.isShown && <p className="answer">{example.answer}</p>}
          </div>
        ))}
      <Link to="/" title="חזור לעמוד הבית" className="back-home" alt="חזור לעמוד הבית">
        <p>חזור לעמוד הבית</p>
      </Link>
      <p></p>
    </div>
  )
}
