import { BrowserRouter, Routes, Route } from "react-router-dom"
import axios from "axios"

import PolicyPage from "./pages/PolicyPage"
import { MainPage } from "./MainPage"
import { QuesExamples } from "./pages/QuesExamples"
import { Login } from "./pages/Login"
import { useEffect, useRef, useState } from "react"
import { makeId } from "./service"
import { MainPassover } from "./MainPassover"

function App() {
  useEffect(() => {
    const start = async () => {
      const userId = makeId()
      var { data } = await axios.post("/start", {
        id: userId,
      })
      const { token } = data
      sessionStorage.setItem("tokenForGeko", token)
    }
    start()
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/ques-examples" element={<QuesExamples />} />
        <Route path="/policy" element={<PolicyPage />} />
        {/* <Route path="/passover" element={<MainPassover />} /> */}
        <Route path="/ques-examples/:tag" element={<QuesExamples />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
